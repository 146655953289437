<template>
  <div class="campaign-wrapper">
    <b-container>
      <div>
        <paragraph class="heading text-center brand-color" text="FIRSATLAR">
        </paragraph>
        <ul class="categoryList">
          <li class="categoryListElement">
            <span
              :class="{ active: selectedCategory === 0 }"
              @click="selectCategory(0)"
            >
              Aktif Fırsatlar
            </span>
          </li>
          <li class="categoryListElement">
            <span
              :class="{ active: selectedCategory === 1 }"
              @click="selectCategory(1)"
            >
              Biten Fırsatlar
            </span>
          </li>
        </ul>
        <b-row class="campaigns">
          <b-col
            cols="12"
            md="6"
            :key="idx"
            v-for="(campaign, idx) in filteredCampaigns"
          >
            <div class="campaign">
              <div class="visualContainer">
                <img
                  class="visual"
                  :src="campaign.visual"
                  :alt="campaign.name"
                  :style="
                    checkEnded(campaign.lastUploadDate)
                      ? 'mix-blend-mode: luminosity; cursor: auto'
                      : ''
                  "
                />
                <div class="overlay squareOverlay">
                  <img src="/assets/images/open.svg" alt="Open icon" />
                  <div class="mt-4">
                    <button-component
                      :style="`background-color: var(--${campaign.shortName}-color) !important;`"
                      name="start"
                      class="start"
                      @click.native="goToCampaign(campaign)"
                      :text="checkEnded(campaign.lastUploadDate) ? 'DETAYLAR' : 'KATIL'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="activeCampaigns.length">
        <campaign-carousel :campaigns="activeCampaigns" />
      </div>
    </b-container>
  </div>
</template>

<script>
import paragraph from "./paragraph.vue";

import buttonComponent from "./button-component.vue"
import moment from 'moment'
import { localize } from 'vee-validate'

export default {
  name: "campaign-grid",
  components: {
    paragraph,
    buttonComponent
    // campaignCarousel
  },
  data: () => {
    return {
      campaigns: [
      { // Çempionlar Lotereyası
          name: 'Çempionlar Lotereyası',
          shortName: 'lotereya',
          url: 'lotereya',
          visual: '/assets/lotereya/desktop-banner.jpg',
          visualMobile: '/assets/lotereya/desktop-banner.jpg',
          startDate: '01.06.2024',
          endDate: '15.09.2024',
          lastUploadDate: '15.09.2024'
        },
        { // P&G Professional Para İadesi Kampanyası
          name: 'P&G Professional Para İadesi Kampanyası',
          shortName: 'pgprofesyonelindirim',
          url: 'pgprofesyonelindirim',
          visual: '/assets/pgprofesyonelindirim/desktop-banner.jpg',
          visualMobile: '/assets/pgprofesyonelindirim/desktop-banner.jpg',
          startDate: '25.01.2024',
          endDate: '15.05.2024',
          lastUploadDate: '22.05.2024'
        },
        // { // Oral-B iO Para İadesi
        //   name: 'Oral-B iO Para İadesi',
        //   shortName: 'oralb-io-cashback',
        //   url: 'oralbio',
        //   visual: '/assets/oralbio/desktop-banner.jpg',
        //   visualMobile: '/assets/oralbio/desktop-banner.jpg',
        //   startDate: '09.05.2024',
        //   endDate: '30.06.2024',
        //   lastUploadDate: '14.07.2024'
        // },
        // { // Carrefoursa Özel
        //   name: "Carrefoursa Özel",
        //   shortName: 'carrefoursaozel',
        //   url: 'carrefoursaozel',
        //   visual:
        //     "/assets/carrefoursaozel/desktop-banner.jpg",
        //   visualMobile:
        //     "/assets/carrefoursaozel/desktop-banner.jpg",
        //   endDate: "30.05.2023",
        //   lastUploadDate: "15.06.2023",
        //   startDate: "15.03.2023",
        //   shortDescription:
        //     "Tüm <strong>P&G</strong> alışverişine 25/70 TL para iadesi",
        //   squareImage:
        //     "/assets/carrefoursaozel/desktop-banner.jpg"
        // },
        { // Gillette Fırsat Yanında
          name: "Gillette Tıraşın En İyilerinde",
          shortName: 'gillettetirasineniyilerinde',
          url: 'gillettetirasineniyilerinde',
          visual:
            "/assets/gillettetirasineniyilerinde/desktop-banner.jpg",
          visualMobile:
            "/assets/gillettetirasineniyilerinde/desktop-banner.jpg",
          endDate: "29.06.2024",
          lastUploadDate: "29.06.2024",
          startDate: "31.08.2023",
          shortDescription:
            "Para İadesi ya da Opet Yakıt Puan <b>KAZAN</b>",
          squareImage:
            "/assets/umutlaparise/desktop-banner.png"
        },
        { // Olimpiyat Cashback
          name: "Olimpiyat Cashback",
          shortName: 'umutlaparise',
          url: 'umutlaparise',
          visual:
            "/assets/umutlaparise/desktop-banner.png",
          visualMobile:
            "/assets/umutlaparise/desktop-banner.png",
          endDate: "31.12.2023",
          lastUploadDate: "31.12.2023",
          startDate: "03.08.2023",
          shortDescription:
            "300 TL ve üzerindeki fişler için 100 TL para iadesi",
          squareImage:
            "/assets/umutlaparise/desktop-banner.png"
        },
        { // Oral B Yorumum
          name: "Oral-B Yorumum",
          shortName: 'oralbyorumum',
          url: 'oralbyorumum',
          visual:
            "/assets/oralbyorumum/desktop-banner.jpg",
          visualMobile:
            "/assets/oralbyorumum/desktop-banner.jpg",
          endDate: "09.06.2023",
          lastUploadDate: "09.06.2023",
          startDate: "22.02.2023",//27.02.2023 for live
          shortDescription:
            "Tüm <strong>P&G</strong> alışverişine 25/70 TL para iadesi",
          squareImage:
            "/assets/oralbyorumum/desktop-banner.jpg"
        },
        { // Orkid Platinum 25/75 TL Cashback
          name: "Orkid 25/75 TL Cashback",
          shortName: 'orkidplatinum',
          url: 'orkidplatinumdeneme',
          visual:
            "/assets/orkidplatinumdeneme/desktop-banner.jpg",
          visualMobile:
            "/assets/orkidplatinumdeneme/mobile-banner.jpg",
          endDate: "31.05.2023",
          lastUploadDate: "15.06.2023",
          startDate: "01.03.2023",//01.02.2023 for live
          shortDescription:
            "Tüm <strong>P&G</strong> alışverişine 25/70 TL para iadesi",
          squareImage:
            "/assets/orkidplatinumdeneme/desktop-banner.jpg"
        },
        { // Fairy
          name: "Fairy Para Iadesi",
          shortName: 'fairyparaiadesi',
          url: 'fairyparaiadesi',
          visual:
            "/assets/fairyparaiadesi/banner-desktop.jpg",
          visualMobile:
            "/assets/fairyparaiadesi/banner-desktop.jpg",
          endDate: "31.05.2023",
          lastUploadDate: "15.06.2023",
          startDate: "02.05.2023",
          shortDescription:
            "Tüm <strong>P&G</strong> alışverişine 25 TL para iadesi",
          squareImage:
            "/assets/fairyparaiadesi/banner-desktop.jpg"
        },
        { // Gilette 40 TL Cashback
          name: "Gilette 40 TL Cashback",
          shortName: 'gilletteparaiadesi',
          url: 'gilletteparaiadesi',
          visual:
            "/assets/gilletteparaiadesi/desktop-banner.jpg",
          visualMobile:
            "/assets/gilletteparaiadesi/desktop-banner.jpg",
          endDate: "30.03.2023",
          lastUploadDate: "31.05.2023",
          startDate: "01.02.2023",
          shortDescription:
            "Tüm <strong>P&G</strong> alışverişine 25 TL para iadesi",
          squareImage:
            "/assets/gilletteparaiadesi/desktop-banner.jpg"
        },
        { // PG 35 Yil
          name: "PG 35 Yil",
          shortName: 'pg35yil',
          url: 'pg35yil',
          visual:
            "/assets/pg35yil/banner_desktop.png",
          visualMobile:
            "/assets/pg35yil/banner_desktop.png",
          endDate: "31.03.2023",
          lastUploadDate: "31.03.2023",
          startDate: "05.01.2023",
          shortDescription:
            "Tüm <strong>P&G</strong> alışverişine 25 TL para iadesi",
          squareImage:
            "/assets/pg35yil/banner_desktop.png"
        },
        { // Orkid 2
          name: "Orkid Cashback",
          shortName: 'orkid-2',
          url: 'orkid',
          visual:
            "/assets/orkid-2/banner_desktop.png",
          visualMobile:
            "/assets/orkid-2/banner_desktop.png",
          endDate: "30.04.2023",
          lastUploadDate: "15.05.2023",
          startDate: "09.11.2022",
          shortDescription:
            "50 TL ve üzeri <strong>P&G</strong> alışverişine 25 TL para iadesi",
          squareImage:
            "/assets/orkid-2/banner_desktop.png"
        },
        { // Olimpiyat
          name: "Her Gün Daha İyiye",
          shortName: 'olimpiyat',
          url: 'olimpiyat',
          visual:
            "/assets/olimpiyat/banner_desktop.png",
          visualMobile:
            "/assets/olimpiyat/banner_mobile.png",
          endDate: "15.08.2022",
          lastUploadDate: "15.08.2022",
          shortDescription:
            "Tüm <strong>P&G</strong> alışverişine 25 TL para iadesi",
          squareImage:
            "/assets/olimpiyat/banner_desktop.png"
        },
        { // Rengarenk
          name: "Rengarenk Bahar Fırsatları",
          shortName: 'bahar',
          url: "rengarenkbaharfirsatlari",
          visual:
            "/assets/bahar/banner_desktop.png",
          visualMobile:
            "/assets/bahar/banner_mobile.png",
          endDate: "13.06.2022",
          lastUploadDate: "13.06.2022",
          shortDescription:
            "Tüm <strong>P&G</strong> alışverişine 20 TL para iadesi",
          squareImage:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/f7b07260-1091-4033-8d5e-9ae202c7049d.png"
        },
        { // Migros
          name: "Migros",
          shortName: 'migros',
          url: "migros",
          visual:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/88b04043-9611-445e-a91d-596863c1cd57.jpeg",
          visualMobile:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/5a447cbc-102c-4d30-860c-4c21a2e2943d.jpeg",
          endDate: "30.04.2022",
          lastUploadDate: "30.04.2022",
          shortDescription:
            "Tüm <strong>P&G</strong> alışverişine 25 TL para iadesi",
          squareImage:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/e5c00204-8b51-4d30-9195-c7d670052638.png"
        },
        { // Carrefour
          name: "Carrefour",
          shortName: "carrefour",
          url: "carrefoursa",
          visual:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/f392186e-a27e-49ec-b7d3-a3fc1eee8413.jpeg",
          visualMobile:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/f392186e-a27e-49ec-b7d3-a3fc1eee8413.jpeg",
          endDate: "30.04.2022",
          lastUploadDate: "30.04.2022",
          shortDescription:
            "Tüm <strong>P&G</strong> alışverişine %20 para iadesi",
          squareImage:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/5103d134-eee6-438f-b44d-8fec08f770a2.png"
        },
        { // Altin firsat 2.0
          name: "Altın Fırsat",
          shortName: "altinfirsat",
          url: "altinfirsat",
          visual:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/altin-firsat/banner_desktop.jpeg",
          visualMobile:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/altin-firsat/banner_desktop.jpeg",
          endDate: "30.04.2022",
          lastUploadDate: "30.04.2022",
          shortDescription:
            "Tüm <strong>P&G</strong> alışverişine %20 para iadesi",
          squareImage:
            "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/5be9475a-9327-4381-939c-b94ce32b485b.png"
        },
        // { // Altin firsat
        //   name: "Altın Fırsatlar",
        //   shortName: "altinfirsatlar",
        //   url: "altinfirsatlar",
        //   visual:
        //     "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/4b18d79b-f3a0-4869-84c1-b6e3899aa88c.jpeg",
        //   visualMobile:
        //     "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/4b18d79b-f3a0-4869-84c1-b6e3899aa88c.jpeg",
        //   endDate: "31.08.2021",
        //   lastUploadDate: "15.09.2021",
        //   shortDescription:
        //     "50 TL ve üzeri <strong>P&G</strong> alışverişine 10 TL para iadesi",
        //   squareImage:
        //     "https://jolt-assets.s3.eu-central-1.amazonaws.com/template/f616ede9-9692-4455-931b-a80d1392a2a2.png"
        // }
      ],
      selectedCategory: 0,
      isTestVersion: false
    };
  },
  props: {
    requires: {
      type: Array
    },
    grid: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  created () {
    this.isTestVersion = this.$route.query.version == 'test'
    if (this.activeCampaigns.length) {
      this.selectedCategory = 0
    } else {
      this.selectedCategory = 1
    }
  },
  computed: {
    activeCampaigns () {
      return this.campaigns.filter((x) => !this.checkEnded(x.lastUploadDate));
    },
    filteredCampaigns () {
      if (this.selectedCategory === 0) {
        return this.activeCampaigns
      } else {
        return this.endedCampaigns
      }
    },
    endedCampaigns () {
      return this.campaigns.filter((x) => this.checkEnded(x.lastUploadDate));
    }
  },
  methods: {
    async goToCampaign (cam) {
      // Import the campaign file dynamically
      const campaign = await require(`../../store/campaigns/${cam.shortName}`).default;
      campaign.pageProperties.testing =
        this.$store.state.pageProperties.testing;
      this.$store.replaceState(campaign);
      this.initState(this.$store.state.pageProperties)

      setTimeout(() => {
        let ended = this.checkEnded(cam.lastUploadDate)
        if (ended) {
          this.goToPage(`${cam.url}/kampanya-bitti`, this)
        } else {
          this.goToPage(cam.url, this)
        }
      }, 10);
    },
    checkEnded (date) {
      if (moment(date, 'DD.MM.YYYY').creationData().format == 'DD.MM.YYYY') {
        date = moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
      const currentTime = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Istanbul"
      });
      const dayInSeconds = 86400; // If we need to include the ending day
      if ((new Date(date).getTime() / 1000 + dayInSeconds < new Date(currentTime).getTime() / 1000) && !this.isTestVersion) {
        return true
      } else {
        return false
      }
    },
    selectCategory (categoryId) {
      this.selectedCategory = categoryId;
    },
    initState (pageProperties) {
      if (pageProperties.dictionary) {
      // merges with built-in phrases
        localize(pageProperties.dictionary);
      }
      if (
        pageProperties.header &&
      pageProperties.header.properties.facebookPixel
      ) {
        const facebookConfig =
        pageProperties.header.properties.facebookPixel;
        this.addFacebookPixel(facebookConfig);
      }

      if (
        pageProperties.header &&
      pageProperties.header.properties.chat
      ) {
        const chat = pageProperties.header.properties.chat;
        if (chat.control) {
          this.addHTML(chat.control);
        }
        if (chat.js) {
          this.addJsFile(chat.js);
        }
        if (chat.css) {
          this.addCssFile(chat.css);
        }
      }

      if (
        pageProperties.header &&
      pageProperties.header.properties.meta
      ) {
        const meta = pageProperties.header.properties.meta;
        if (meta) {
          this.meta = meta;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.categoryList {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.categoryListElement {
  display: inline;
  margin: 0 10px;
  text-align: center;
  &:hover {
    color: var(--brand-light-color);
    cursor: pointer;
  }
}
.active {
  color: var(--brand-light-color);
  font-weight: 700;
}
.clock {
  margin: 0 10px;
}
.campaignDescription {
  > p {
    margin: 0;
  }
  padding: 20px;
  font-size: 20px;
}
.campaignEndDate {
  display: flex;
  margin-bottom: 20px;
  font-size: 18px;
}
@media (max-width: 991px) {
  .campaignEndDate {
    font-size: 14px;
    margin: 0;
  }
  .campaignDescription {
    font-size: 14px;
    padding: 0;
  }
}
@media (max-width: 500px) {
  .campaignEndDate {
    font-size: 13px;
    margin: 0;
  }
  .campaignDescription {
    font-size: 13px;
    padding: 0;
  }
}
.brand-color {
  color: var(--brand-light-color);
}
.campaign-wrapper {
  padding: 50px 0 30px 0;

  @media (max-width: 767px) {
    padding: 40px 0 0;
  }
}

.campaigns {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  & > div {
    margin-bottom: 30px;
  }

  .visual {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .visualContainer {
    position: relative;
    flex: 1;
    cursor: pointer;
  }

  .descriptionContainer {
    flex: 1;
    padding: 10px;
  }

  .campaign {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    transition: box-shadow 0.5s;
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      > .visualContainer > .overlay {
        opacity: 1;
      }
    }
  }

  .squareOverlay {
    // border-top-right-radius: 0 !important;
    // border-bottom-right-radius: 0 !important;
    width: 100% !important;
  }

  .overlay {
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    opacity: 0;
    height: 100%;
    color: #fff;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.3s;

    img {
      width: 40px;
      height: 40px;

      @media (max-width: 992px) {
        width: 30px;
        height: 30px;
      }
    }

    .start {
      width: 150px;
      height: 40px;
      background-color: #fbda00;
      border-radius: 6px;
      border-width: 2px;
      border-color: #fff;
      color: #fff;
      font: normal normal 600 18px/22px Montserrat;

      @media (max-width: 992px) {
        width: 150px;
        height: 35px;
        font: normal normal 600 14px/18px Montserrat;
      }
    }
  }
}
</style>
