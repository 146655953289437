import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter);
function loadPage (pagePath) {
  return () => import(`@/${pagePath}`);
}

const goTo = (to, from, next) => {
  console.log("ROUTE", this, from, next)
}
const routes = [
  {
    path: '/sitedown',
    name: 'Sitedown',
    component: loadPage('views/Maintenance.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: loadPage('views/Home.vue')
  },
  {
    path: '/geoLimit',
    name: 'geo',
    component: loadPage('views/GeoLimit.vue')
  },
  {
    path: '/404',
    name: '404',
    component: loadPage('views/404.vue')
  },
  {
    path: '/*',
    name: 'anyRootPath',
    component: loadPage('views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes
})
let campaigns = {
  'rengarenkbaharfirsatlari': 'bahar',
  'migros': 'migros',
  'carrefoursa': 'carrefour',
  'altinfirsat': 'altinfirsat',
  // 'altinfirsatlar': 'altinfirsatlar',
  'baharevinizde': 'baharozel',
  'olimpiyat': 'olimpiyat',
  'orkid': 'orkid-2',
  'pg35yil': 'pg35yil',
  'dishekimlerio': 'dishekimlerio',
  'gilletteparaiadesi': 'gilletteparaiadesi',
  'fairyparaiadesi':'fairyparaiadesi',
  "orkidplatinumdeneme": "orkidplatinum",
  'oralbyorumum': 'oralbyorumum',
  'cashback-123': 'cashback-123',
  'carrefoursaozel': 'carrefoursaozel',
  'umutlaparise': 'umutlaparise',
  'gillettetirasineniyilerinde' : 'gillettetirasineniyilerinde',
  'oralbio': 'oralb-io-cashback',
  'fixodentparaiadesi': 'fixodentparaiadesi',
  'pgprofesyonelindirim': 'pgprofesyonelindirim',
  'lotereya' : 'lotereya'
}

const VUE_APP_SITE_DOMAIN = process.env.VUE_APP_SITE_DOMAIN
let toLoop = true;
router.beforeEach((to, from, next) => {
  // if the user is not authenticated, `next` is called twice
  if (to.name === "security" && toLoop) {
    toLoop = false;
    // router.push(".wellknown/security.txt")
    window.location.href = `${VUE_APP_SITE_DOMAIN}.wellknown/security.txt`;
  } else {
    const path = window.location.pathname;
    if (path.split("/")[1] && path.split("/")[1] == 'gilletteDynamic') return window.location.href = '/gilletteparaiadesi?utm_source=QR&utm_medium=QR'
    Object.keys(campaigns).forEach(x => {
      if (path.startsWith(`/${x}`)) {
        const campaign = require(`../store/campaigns/${campaigns[x]}`).default;
        campaign.pageProperties.testing = store.state.pageProperties.testing;
        store.replaceState(campaign);
      }
    })

    /**
     * Check the campaign ending date, and if its true,
     * redirect to 'kampanya-bitti' page
     */

    const endDate = new Date(store.state.campaignEndDate).toLocaleString('en-US', { timeZone: 'Europe/Istanbul' });
    const currentTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Istanbul' });
    const dayInSeconds = 86400; // If we need to include the ending day
    let camEnded = (new Date(endDate).getTime() / 1000 + dayInSeconds) < (new Date(currentTime).getTime() / 1000)
    let allowedPages = ['kampanya-bitti', 'katilim-kosullari', 'sss', 'tekrar-fis-yukle', 'tekrar-urun-yukle', 'iban-yeniden-gonderme']
    if (camEnded) {
      if (!allowedPages.find(x => path.includes(x))) {
        window.location.href = `${path}/kampanya-bitti`
      }
    }
    next();
  }
});

export default router
